import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import { textToBase64 } from 'utils/functions';
import { computed, ref, toValue, watch, watchEffect } from 'vue';
import { ResultHeaderData, ResultItemData } from './types';
import { getResultText, isValidTag } from './utils';

const STATE_OK = 'ok';
const STATE_LOADING = 'loading';
const STATE_ERROR = 'error';

const WARN_THRESHOLD = 50;

export function useResults(urlRef, searchRef, tagsRef, tagsPrefixValueRef, tagsPrefixResultRef) {
  const results = ref([]);
  const page = ref(1);
  const more = ref(false);
  const state = ref(STATE_OK);
  const errorMessage = ref(null);

  const hasMore = computed(() => state.value === STATE_OK && more.value);
  const onLoadMore = () => {
    if (hasMore.value) {
      page.value += 1;
    }
  };

  const isLoading = computed(() => state.value === STATE_LOADING);
  const isError = computed(() => state.value === STATE_ERROR);

  watch([urlRef, searchRef], () => {
    results.value = [];
    page.value = 1;
    more.value = false;
    state.value = STATE_OK;
  });

  watchEffect(async onCleanup => {
    const url = toValue(urlRef);
    const searchTerm = toValue(searchRef)?.trim() ?? '';
    const body = {
      page: String(page.value),
      fulltext: searchTerm
    };

    const controller = new AbortController();
    const signal = controller.signal;

    onCleanup(() => controller.abort());

    try {
      state.value = STATE_LOADING;

      const response = await Fetch.post(url, body, { signal });
      if (response.ok) {
        state.value = STATE_OK;
        more.value = response.body.more;

        if (response.body.results.length > WARN_THRESHOLD) {
          Log.warn(`Received more than ${WARN_THRESHOLD} select results at once from ${url}`);
        }

        response.body.results.forEach(result => {
          results.value.push(result.type === 'header' ? new ResultHeaderData(result) : new ResultItemData(result));
        });

        if (toValue(tagsRef) && !response.body.more && searchTerm) {
          if (!isValidTag(searchTerm)) {
            state.value = STATE_ERROR;
            errorMessage.value = I18N.t("tag_create_format_error");
          } else if (!exists(searchTerm, results.value)) {
            // We will allow to create new tag only in case we don't have exactly the same tag
            const id = (toValue(tagsPrefixValueRef) ?? '') + textToBase64(searchTerm);
            const html = getResultText((toValue(tagsPrefixResultRef) ?? '') + searchTerm);
            results.value.push(new ResultItemData({ id, text: searchTerm, html }));
          }
        }
      } else {
        state.value = STATE_ERROR;

        Log.error('Unexpected response status ' + response.status, {
          source: 'VueSelect',
          url: url,
          requestBody: body,
          responseBody: response.body
        });
      }
    } catch (e) {
      if (e.name !== 'AbortError') {
        state.value = STATE_ERROR;
        Log.error(e);
      }
    }
  });

  return { results, onLoadMore, isLoading, isError, errorMessage };
}

function exists(tag, results) {
  return results
    .filter(result => result.type !== 'header')
    .some(res => res.text === tag);
}
