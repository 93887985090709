export function deepEqual(a, b) {
  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }
    for (var key in a) {
      if (!a.hasOwnProperty(key)) {
        if (b.hasOwnProperty(key)) {
          return false;
        }
        continue;
      }
      if (!b.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }

  return a === b;
}

export function delay(timeout, value) {
  return new Promise(resolve => {
    setTimeout(() => resolve(value), timeout);
  });
}

export function debounce(timeout, callback) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(this, args), timeout);
  };
}

export function digest(message) {
  // encode as UTF-8
  const messageBuffer = new TextEncoder().encode(message);

  // hash the message
  return crypto.subtle.digest('SHA-256', messageBuffer)
    .then(hashBuffer => {
      // convert ArrayBuffer to Array
      const hashArray = Array.from(new Uint8Array(hashBuffer));

      // convert bytes to hex string
      return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    });
}

export function copyToClipboard(str) {
  function listener(e) {
    e.preventDefault();
    e.clipboardData.setData('text/html', str);
    e.clipboardData.setData('text/plain', str);
  }
  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
}

export function createError(message, properties = {}) {
  let error;
  if (message instanceof Error) {
    error = message;
  } else {
    error = new Error(message);
  }

  for (const [key, value] of Object.entries(properties)) {
    error[key] = value;
  }

  return error;
}

export function formatAttrSelector(attr, value) {
  if (value) {
    return '[' + attr + '="' + value.replace(/[.,:='"\[\]]/g, '\\$&') + '"]';
  }
  return '[' + attr + ']';
}

export function abbreviate(value, threshold) {
  if (threshold > value.length) {
    return value;
  }

  const original = value.split(' ').filter(part => part);
  const abbreviated = [];

  let result = value;
  while (result.length > threshold && original.length > 0) {
    abbreviated.unshift(original.pop().charAt(0) + '.');

    result = original.concat(abbreviated).join(' ');
  }

  return result;
}

export function randomId() {
  return Math.random().toString(16).slice(2);
}

export function updateUrl(url, params) {
  url = new URL(url, window.location.origin);

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  return url.toString();
}

export function redirect(url) {
  // we need to normalize provided URL because it might be not absolute and comparison below would not work
  const targetUrl = new URL(url, window.location.origin);

  const newUrlNoHash = targetUrl.href.replace(targetUrl.hash, '');
  const oldUrlNoHash = window.location.href.replace(window.location.hash, '');

  window.location.href = targetUrl.href;

  // we need to reload page if URL differs only in hash part (or is the same in case there is no hash part) because
  // browser will not do it
  if (newUrlNoHash === oldUrlNoHash) {
    window.location.reload();
  }
}

export function textToBase64(text) {
  return btoa(String.fromCodePoint(...new TextEncoder().encode(text)));
}
