import Fetch from 'core/fetch';
import Log from 'core/log';
import { ref } from 'vue';
import { Config } from './Types';

export function useConfig() {
  const config = ref(new Config());

  const loadConfig = async (configUrl) => {
    try {
      const response = await Fetch.getJson(configUrl);
      if (response.ok) {
        config.value = new Config(response.body);
      } else {
        Log.error('Unexpected response status ' + response.status, {
          source: 'ActivityStream',
          url: configUrl,
          responseBody: response.body
        });
      }
    } catch (e) {
      Log.error(e);
    }
  };

  return { config, loadConfig };
}
