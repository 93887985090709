import VueRegistry from '../registry';
import Avatar from './Avatar/Avatar.vue';
import Badge from './Badge/Badge.vue';
import CustomerActivityStream from './Customer/ActivityStream/ActivityStream.vue';
import Example from './Example.vue';
import QuadroContainer from './QuadroContainer/QuadroContainer.vue';
import SelectSingle from './select/SelectSingle.vue';
import SelectMulti from './select/SelectMulti.vue';
import UserMenu from './UserMenu/UserMenu.vue';

VueRegistry.registerComponent('Example', Example);
VueRegistry.registerComponent('SelectSingle', SelectSingle);
VueRegistry.registerComponent('SelectMulti', SelectMulti);
VueRegistry.registerComponent('CustomerActivityStream', CustomerActivityStream);
VueRegistry.registerComponent('UserMenu', UserMenu);

// TODO replace with components from realpad-crm-frontend-lib
VueRegistry.registerComponent('Avatar', Avatar);
VueRegistry.registerComponent('Badge', Badge);
VueRegistry.registerComponent('QuadroContainer', QuadroContainer);
