import I18N from 'core/i18n';
import $ from 'jquery';
import { formatAttrSelector } from 'utils/functions';

const ELEMENT_SELECTOR = 'input:not(.custom-file-input),select,textarea';
const HIDDEN_SELECTOR = 'input[type=hidden]';

function validate(element) {
  if (element instanceof $) {
    let valid = true;

    element.each(function() {
      valid &= validate(this);
    });

    return valid;
  }

  if (element.matches('form')) {
    let valid = true;

    element.querySelectorAll(ELEMENT_SELECTOR).forEach(child => {
      valid &= validate(child);
    });

    return valid;
  }

  if (element.matches(ELEMENT_SELECTOR)) {
    const valid = isValid(element);

    setClasses(element, valid);
    setMessage(element, valid);

    return valid;
  }

  return true;
}

function isValid(element) {
  if (typeof element.checkValidity !== 'function') {
    return true;
  }

  // checkValidity() always returns true for hidden inputs
  // instead we check ValidityState + required attribute manually
  if (element.matches(HIDDEN_SELECTOR)) {
    if (!element.validity.valid) {
      return false;
    }
    if (element.matches('[required]:not([disabled])')) {
      return !!element.value;
    }
    return true;
  }

  return element.checkValidity();
}

function setClasses(element, valid) {
  element.classList.toggle('is-invalid', !valid);

  if (element.classList.contains('file-input-hidden')) {
    element.parentNode.querySelectorAll('.custom-file-input,.file-input-preview')
      .forEach(input => input.classList.toggle('is-invalid', !valid));
  }

  if (element.classList.contains('rp-radio-btn-input')) {
    const group = element.closest('.rp-radio-btn-group');
    if (group) {
      group.classList.toggle('is-invalid', !valid);
    }
  }

  const container = element.closest('.form-control-container');
  if (container) {
    container.classList.toggle('is-invalid', !valid);
  }
}

function setMessage(element, valid) {
  const container = element.closest('.form-control-container');
  if (container) {
    const feedback = container.querySelector('.invalid-feedback');
    if (feedback) {
      feedback.textContent = getMessage(element, valid);
    }
  }
}

function getMessage(element, valid) {
  if (valid) {
    return '';
  }

  const state = element.validity;

  if (state.customError) {
    return element.dataset.validationMessage || '';
  }

  if (state.patternMismatch) {
    return element.dataset.patternMessage || '';
  }

  if (state.tooLong) {
    return I18N.t('form_validation_max_length', element.maxLength);
  }

  if (state.tooShort) {
    return I18N.t('form_validation_min_length', element.minLength);
  }

  if (state.typeMismatch) {
    if (element.type === 'email') {
      return I18N.t('form_validation_email');
    }
    return '';
  }

  if (state.valueMissing) {
    return I18N.t('form_validation_required');
  }

  if (element.matches(HIDDEN_SELECTOR)) {
    if (element.matches('[required]')) {
      return I18N.t('form_validation_required');
    }
    return '';
  }

  return '';
}

function setError(element, message) {
  if (element instanceof $) {
    element.each(function() {
      setError(this, message);
    });

    return;
  }

  if (!element.matches(ELEMENT_SELECTOR)) {
    return;
  }
  if (typeof element.setCustomValidity !== 'function') {
    return;
  }

  element.setCustomValidity(message || '');
  element.dataset.validationMessage = message || '';

  validate(element);
}

function setErrors(root, errors = {}) {
  if (root instanceof $) {
    root.each(function() {
      setErrors(this, errors);
    });

    return;
  }

  for (const [name, message] of Object.entries(errors)) {
    const element = root.querySelector(formatAttrSelector('name', name));
    if (element) {
      setError(element, message);
    }
  }
}

export default class Validation {
  static validate(element) {
    return validate(element);
  }

  static setError(element, message) {
    setError(element, message);
  }

  static setErrors(root, errors) {
    setErrors(root, errors);
  }
}

$(() => {
  $(document).on('submit', 'form.validated:not(.inline-form,.modal-form)', event => {
    if (!Validation.validate(event.target)) {
      event.preventDefault();
    }
  });

  $(document).on('change', 'form.validated :input', event => {
    const element = event.target;

    Validation.setError(element, null);
    Validation.validate(element);
  });
});
