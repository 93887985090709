import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import { ref } from 'vue';
import { ActiveProfile } from './userMenuTypes';

export function useMenuData() {
  const activeProfile = ref(new ActiveProfile({
    name: I18N.t('loading'),
    email: '...'
  }));
  const details = ref(null);

  const loadMenuData = async (url) => {
    try {
      const response = await Fetch.getJson(url);
      if (response.ok) {
        activeProfile.value = response.body['activeProfile'];
        details.value = response.body['details'];
      } else {
        Log.error('Unexpected response status ' + response.status, {
          source: 'UserMenu',
          url: url,
          responseBody: response.body
        });
      }
    } catch (e) {
      Log.error(e);
    }
  };

  return { activeProfile, details, loadMenuData };
}
