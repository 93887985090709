import { debounce } from '../../utils/functions';

export default class StickyHeader {
  constructor(table) {
    this.table = table;
    this.$element = table.$element;
    this.$container = this.$element.find('.rp-table-scroll-container');

    this.$window = $(window);
    this.$pageHeader = $('header');

    this.$table = table.$table;
    this.$thead = this.$table.find('thead');
    this.$sticky = this.$element.find('.rp-table-sticky-header');

    this.$tableHeaders = this.$table.find('th.rp-column-header');
    this.$stickyHeaders = this.$sticky.find('th.rp-column-header');

    this.deboucedResize = debounce(100, () => this.resize());

    this.bindEvents();
    this.resize();
  }

  bindEvents() {
    this.$element.on('rp-table:loaded', () => this.resize());
    this.$window.on('resize', () => this.deboucedResize());

    if (this.$element.is('.rp-table-fit-mode')) {
      this.$container.on('scroll', () => this.onContainerScroll());
    } else {
      this.$window.on('scroll', () => this.onWindowScroll());
    }
  }

  onContainerScroll() {
    const scrollTop = this.$container.scrollTop();
    if (scrollTop > 0) {
      this.$sticky.hidden(false).css('top', scrollTop + 'px');
    } else {
      this.$sticky.hidden().css('top', '');
    }

    this.deboucedResize();
  }

  onWindowScroll() {
    const thresholdTop = this.$thead.offset().top - this.$pageHeader.outerHeight();
    const thresholdBottom = thresholdTop + (this.$table.outerHeight() - this.$thead.outerHeight());

    const scrollTop = this.$window.scrollTop();
    if (scrollTop > thresholdTop) {
      const top = Math.min(scrollTop, thresholdBottom) - thresholdTop - 1;
      this.$sticky.hidden(false).css('top', top + 'px');
    } else {
      this.$sticky.hidden().css('top', '');
    }

    this.deboucedResize();
  }

  resize() {
    this.$sticky.css('width', this.$table.outerWidth());

    for (let i = 0; i < this.$tableHeaders.length; i++) {
      const $tableHeader = $(this.$tableHeaders[i]);
      const $stickyHeader = $(this.$stickyHeaders[i]);

      $stickyHeader
        .css('width', $tableHeader.outerWidth())
        .css('height', $tableHeader.outerHeight());
    }
  }
}
