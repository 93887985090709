export class Config {
  constructor(data = {}) {
    this.itemsUrl = data['items_url'];
    this.filterableTypes = data['filterable_types'] ?? [];
    this.actionCreateTask = data['action_create_task'];
    this.actionCreateInteraction = data['action_create_interaction'];
  }
}

export class BaseItem {
  constructor(data) {
    this.id = data['id'];
    this.type = data['type'];
    this.typeName = data['type_name'];
    this.timestamp = new Date(data['timestamp']);
  }
}

export class EmailItem extends BaseItem {
  constructor(data) {
    super(data);

    this.emailFrom = [];
    if (data['email_from']) {
      for (const address of data['email_from']) {
        this.emailFrom.push(new EmailAddress(address));
      }
    }

    this.emailTo = [];
    if (data['email_to']) {
      for (const address of data['email_to']) {
        this.emailTo.push(new EmailAddress(address));
      }
    }

    this.emailCc = [];
    if (data['email_cc']) {
      for (const address of data['email_cc']) {
        this.emailCc.push(new EmailAddress(address));
      }
    }

    this.emailSubject = data['email_subject'];
    this.emailContent = data['email_content'];

    this.emailAttachments = [];
    if (data['email_attachments']) {
      for (const attachment of data['email_attachments']) {
        this.emailAttachments.push(new EmailAttachment(attachment));
      }
    }
  }
}

export class EmailAddress {
  constructor(data) {
    this.address = data['address'];
    this.name = data['name'];

    switch (data['type']) {
      case 'customer':
        this.icon = 'person';
        break;
      case 'salesman':
        this.icon = 'support_agent';
        break;
    }
  }
}

export class EmailAttachment {
  constructor(data) {
    this.downloadUrl = data['download_url'];
    this.fileName = data['file_name'];
    this.fileSize = data['file_size'];
  }
}

export class TaskItem extends BaseItem {
  constructor(data) {
    super(data);
    this.salesmanName = data['salesman_name'];
    this.taskType = data['task_type'];
    this.taskStatusColor = data['task_status_color'];
    this.taskStatusName = data['task_status_name'];
    this.taskText = data['task_text'];
    this.taskDeadline = data['task_deadline'] ? new Date(data['task_deadline']) : null;
    this.taskCompleted = data['task_completed'] ? new Date(data['task_completed']) : null;
    this.taskCompleteAction = data['task_complete_action'];
    this.taskCancelAction = data['task_cancel_action'];
    this.taskReopenAction = data['task_reopen_action'];
    this.taskUpdateAction = data['task_update_action'];
    this.taskDeleteAction = data['task_delete_action'];
  }

  get hasActions() {
    return this.taskCompleteAction || this.taskCancelAction || this.taskReopenAction
      || this.taskUpdateAction || this.taskDeleteAction;
  }
}

export class InteractionItem extends BaseItem {
  constructor(data) {
    super(data);
    this.salesmanName = data['salesman_name'];
    this.interactionType = data['interaction_type'];
    this.interactionNote = data['interaction_note'];
    this.interactionUpdateAction = data['interaction_update_action'];
    this.interactionDeleteAction = data['interaction_delete_action'];
  }

  get hasActions() {
    return this.interactionUpdateAction || this.interactionDeleteAction;
  }
}

export class EventItem extends BaseItem {
  constructor(data) {
    super(data);
    this.salesmanName = data['salesman_name'];
    this.eventType = data['event_type'];
    this.eventText = data['event_text'];
    this.eventTaskType = data['event_task_type'];
    this.eventDeal = data['event_deal'];
    this.eventDealUrl = data['event_deal_url'];
    this.eventProject = data['event_project'];
    this.eventProjectUrl = data['event_project_url'];
    this.eventUnit = data['event_unit'];
    this.eventUnitUrl = data['event_unit_url'];
  }
}

export class DateItem {
  constructor(date) {
    this.id = `date-${date}`;
    this.type = 'date';
    this.date = date;
  }
}
