<script setup>
import { onMounted, ref } from 'vue';
import { EmailItem } from './Types';

defineProps({
  item: {
    type: EmailItem,
    required: true
  }
});

const element = ref(null);
const expandable = ref(false);
const expanded = ref(false);

onMounted(() => {
  if (element.value.scrollHeight > element.value.clientHeight) {
    expandable.value = true;
  }
});
</script>

<template>
  <div class="rpvue-ActivityStreamEmailContent" :class="{ 'is-expanded': expanded }" ref="element">
    <div class="rpvue-ActivityStreamEmailContent-subject">{{ item.emailSubject }}</div>
    <div class="rpvue-ActivityStreamEmailContent-html" v-html="item.emailContent"></div>
    <!-- TODO remove once quoted content is collapsible -->
    <div class="rpvue-ActivityStreamEmailContent-overflow"
         v-if="expandable && !expanded"
         @click.prevent="expanded = true">
      <rp-icon class="rpvue-ActivityStreamEmailContent-overflowIcon" name="expand_more"></rp-icon>
    </div>
  </div>
</template>
