<script setup>
import { Button, ButtonSize, DropdownAlign, DropdownRow, SplitButton } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { Config } from './Types';
import { useClickActions } from '../../useClickActions';

const props = defineProps({
  config: {
    type: Config,
    required: true
  }
});

const { clickAction, disabled } = useClickActions();

const onCreateTaskClick = () => clickAction('modal', props.config.actionCreateTask);
const onCreateInteractionClick = () => clickAction('modal', props.config.actionCreateInteraction);
</script>

<template>
  <div class="rpvue-ActivityStreamHeader">
    <div class="rpvue-ActivityStreamHeader-title">
      <span class="rpvue-u-TypoTitle rpvue-u-TypoTitle--medium">
        {{ I18N.t('customer_tab_activities') }}
      </span>
    </div>
    <div class="rpvue-ActivityStreamHeader-actions">
      <SplitButton v-if="config.actionCreateTask && config.actionCreateInteraction"
                   :align="DropdownAlign.END"
                   :size="ButtonSize.SM"
                   :disabled="disabled"
                   @click="onCreateTaskClick">
        <template v-slot:default>
          {{ I18N.t('crm_task_create') }}
        </template>
        <template v-slot:dropdown="{ close }">
          <DropdownRow @click="onCreateInteractionClick();close()">
            {{ I18N.t('customer_interaction_create') }}
          </DropdownRow>
        </template>
      </SplitButton>

      <Button v-else-if="config.actionCreateTask"
              :size="ButtonSize.SM"
              :disabled="disabled"
              @click="onCreateTaskClick">
        {{ I18N.t('crm_task_create') }}
      </Button>

      <Button v-else-if="config.actionCreateInteraction"
              :size="ButtonSize.SM"
              :disabled="disabled"
              @click="onCreateInteractionClick">
        {{ I18N.t('customer_interaction_create') }}
      </Button>
    </div>
  </div>
</template>
